import Script from "next/script";
import React, { Component, useEffect, useState } from "react";

const Scripts = () => {
  const [shouldLoadScripts, setShouldLoadScripts] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShouldLoadScripts(true);
    }, 2000);
  });

  if (!shouldLoadScripts) return null;

  return (
    <>
      <Script src={`https://www.googletagmanager.com/gtag/js?id=G-QHB2NMD8FT`} id="google-tag-load" />

      <Script
        dangerouslySetInnerHTML={{
          __html: `
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                  gtag('config', 'G-QHB2NMD8FT', {
                    page_path: window.location.pathname,
                  });
                `,
        }}
        id="google-tag-script"
      />

      <Script
        dangerouslySetInnerHTML={{
          __html: `
              (function(c,l,a,r,i,t,y){
                c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", "959wnky8ze");
              `,
        }}
        id="microsoft-clarity-script"
      />

      <Script
        dangerouslySetInnerHTML={{
          __html: `
              !function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
              },s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='//static.ads-twitter.com/uwt.js',
              a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
              // Insert Twitter Pixel ID and Standard Event data below
              twq('init','o9j0l');
              twq('track','PageView');
              `,
        }}
        id="twitter-pixel"
      />

      <Script
        dangerouslySetInnerHTML={{
          __html: `
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '426903442719858');
              fbq('track', 'PageView');
              `,
        }}
        id="facebook-pixel"
      />

      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: "none" }}
          src="https://www.facebook.com/tr?id=426903442719858&ev=PageView&noscript=1"
        />

        <noscript>
          <style
            type="text/css"
            dangerouslySetInnerHTML={{
              __html: `.hide-for-observer {
                visibility: visible;
            }`,
            }}
          ></style>
        </noscript>
      </noscript>
    </>
  );
};

export default Scripts;
