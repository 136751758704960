import { useEffect } from "react";
import "../assets/styles/index.scss";
import { Toaster } from "react-hot-toast";
import ProgressBar from "@badrap/bar-of-progress";
import Router from "next/router";
import Scripts from "../components/scripts";
import countryCodes from "../assets/js/utils/countries.json";

const progress = new ProgressBar({
  size: 2,
  color: "#F79B01",
  className: "bar-of-progress",
  delay: 100,
});

Router.events.on("routeChangeStart", progress.start);
Router.events.on("routeChangeComplete", progress.finish);
Router.events.on("routeChangeError", progress.finish);

const MyApp = ({ Component, pageProps }) => {
  useEffect(() => {
    const fn = async () => {
      const payload = await (await fetch("https://api.iplocation.net/?cmd=get-ip")).json();
      if (payload) {
        const location = await (await fetch("https://api.iplocation.net/?ip=" + payload.ip)).json();
        if (location) {
          const code = countryCodes.find((c) => c.code === location.country_code2);
          localStorage.setItem("ip-lookup-country-code", code?.dial_code);
        }
      }
    };
    if (localStorage.getItem("ip-lookup-country-code") === null) fn();
  }, []);

  return (
    <>
      <Component {...pageProps} />
      <Toaster position="bottom-right" reverseOrder={false} />
      <Scripts />
    </>
  );
};

export default MyApp;
